import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { documentsApi } from "api";
import { localStore } from "helpers/store";
import { isValidUUID } from "helpers/util";
import NotFound404 from "routes/NotFound404";
import { MAX_DOCUMENTS_IN_HISTORY } from "const";

const getLayoutForCurrentWidth = () => {
    return window.innerWidth < 1000 ? "stacked" : "sidebar";
};

export default function ViewDocument(props) {
    const [documentUrl, setDocumentUrl] = useState(null);
    const [layout, setLayout] = useState(getLayoutForCurrentWidth());
    const { documentUuid } = useParams();
    const validDocumentUuid = isValidUUID(documentUuid);
    const [show404Error, setShow404Error] = useState(!validDocumentUuid);

    useEffect(() => {
        if (validDocumentUuid) {
            documentsApi
                .get(documentUuid)
                .then((response) => {
                    document.title = response.data.title || "SwaggerBin Document";
                    setDocumentUrl(response.data.fileUrl);
                    props.onSetActiveDocument(response.data);

                    localStore.addDocumentToHistory(
                        {
                            title: response.data.title,
                            description: response.data.description,
                            uuid: response.data.uuid,
                        },
                        MAX_DOCUMENTS_IN_HISTORY
                    );
                })
                .catch(() => {
                    props.onSetActiveDocument(null);
                    setShow404Error(true);
                });
        }

        const handleResize = () => {
            setLayout(getLayoutForCurrentWidth());
        };
        window.addEventListener("resize", handleResize);

        // called when leaving the page
        return () => {
            props.onSetActiveDocument(null);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {/* available options: https://meta.stoplight.io/docs/elements/ZG9jOjMyNjU4OTY1 */}
            {documentUrl && validDocumentUuid && (
                <elements-api
                    apiDescriptionUrl={documentUrl}
                    router="hash"
                    layout={layout}
                    collapseTableOfContents="true"
                />
            )}

            {show404Error && (
                <NotFound404 errorMessage="The document you're looking for may be expired and it's not available anymore." />
            )}
        </>
    );
}
