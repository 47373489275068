import React, { useState } from "react";
import { AppBar, Toolbar, Button, Snackbar, Alert, Typography } from "@mui/material";
import { getContentFormat } from "helpers/converter";

export default function ControlBar(props) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

    const handleFileSelect = (event) => {
        var files = event.target.files;
        if (!files.length) return;

        var reader = new FileReader();
        var file = files[0];

        reader.onload = (() => {
            return function (e) {
                let content = e.target.result;

                const format = getContentFormat(content);
                if (format !== null) {
                    props.onCodeChange(content);
                } else {
                    setErrorMessage("File format unknown. Expecting JSON or YAML file.");
                    setErrorSnackbarVisible(true);
                }
            };
        })(file);

        reader.readAsText(file);
    };

    const handleClear = () => {
        props.onCodeChange("");
    };

    return (
        <>
            <AppBar position="static" className="control-bar">
                <Toolbar variant="dense" className="toolbar">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Paste your OpenAPI document as JSON or YAML
                    </Typography>{" "}
                    <Button variant="outlined" component="label">
                        Upload File
                        <input type="file" accept=".json, .yaml, .yml" hidden onChange={(e) => handleFileSelect(e)} />
                    </Button>
                    <Button variant="outlined" onClick={() => handleClear()} sx={{ marginLeft: "1rem" }}>
                        Clear
                    </Button>
                </Toolbar>
            </AppBar>

            <Snackbar
                open={errorSnackbarVisible}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setErrorSnackbarVisible(false)}
            >
                <Alert severity="error" variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
