import React, { useState, useEffect } from "react";
import { Grid, Container, Stack } from "@mui/material";
import CodeField from "components/CodeField";
import ControlBar from "components/ControlBar";
import UploadWidget from "components/UploadWidget";
import HistoryWidget from "components/HistoryWidget";
import { TEST_DOCUMENT } from "testdata/openapi";
import { objectToJson } from "helpers/util";

export default function Home() {
    const [codeContent, setCodeContent] = useState(objectToJson(TEST_DOCUMENT));

    useEffect(() => {
        document.title = "SwaggerBin - Paste and share your OpenAPI documents";
    }, []);

    const handleOnCodeChange = (content) => {
        setCodeContent(content);
    };

    return (
        <>
            <Container maxWidth={false} className="content-container">
                <Grid container spacing={2} sx={{ alignItems: "stretch" }} className="grid-container">
                    <Grid item md={8} lg={9} sx={{ width: "100%" }}>
                        <Stack spacing={2} sx={{ height: "100%" }}>
                            <ControlBar onCodeChange={(content) => handleOnCodeChange(content)} />
                            <CodeField content={codeContent} onCodeChange={(content) => handleOnCodeChange(content)} />
                        </Stack>
                    </Grid>
                    <Grid item md={4} lg={3} className="col-2">
                        <UploadWidget codeContent={codeContent} />
                        <HistoryWidget />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
