import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "components/Navbar";
import Home from "routes/Home";
import ViewDocument from "routes/ViewDocument";
import NotFound404 from "routes/NotFound404";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";

function App() {
    const [activeDocument, setActiveDocument] = useState(null);

    return (
        <ThemeProvider theme={theme}>
            <Navbar activeDocument={activeDocument} />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/doc/:documentUuid" element={<ViewDocument onSetActiveDocument={setActiveDocument} />} />
                <Route path="*" element={<NotFound404 />} />
            </Routes>
        </ThemeProvider>
    );
}

export default App;
