import React from "react";
import { List, ListItem, ListItemButton, ListItemText, Tooltip, Typography, ListItemIcon } from "@mui/material";
import { NavLink } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import { localStore } from "helpers/store";
import { docUuidToUrl } from "helpers/util";

export default function HistoryWidget() {
    const history = localStore.getDocumentHistory();

    const historyList = history.map((item) => {
        return (
            <ListItem key={item.uuid} disablePadding>
                <Tooltip title={item.description} disableInteractive>
                    <ListItemButton component={NavLink} to={docUuidToUrl(item.uuid)}>
                        <ListItemIcon>
                            <ArticleIcon sx={{ color: "#0d6efd" }} />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </Tooltip>
            </ListItem>
        );
    });

    const showList = historyList.length !== 0;

    return (
        <>
            {showList && (
                <>
                    <Typography component="div" variant="h5" sx={{ marginTop: "2rem" }}>
                        Last accessed documents
                    </Typography>
                    <List>{historyList}</List>
                </>
            )}
        </>
    );
}
