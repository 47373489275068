import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, Typography, Tooltip } from "@mui/material";
import FileSaver from "file-saver";

export default function Navbar(props) {
    const NavLinkButton = (props) => {
        return (
            <Button {...props} color="inherit" component={NavLink} className="nav-button">
                {props.title}
            </Button>
        );
    };

    const handleExportButtonClick = () => {
        if (!props.activeDocument) return;
        FileSaver.saveAs(props.activeDocument.fileUrl, props.activeDocument.fileName);
    };

    const location = useLocation();
    const showCreateNewPasteButton = location.pathname !== "/";

    return (
        <AppBar position="static" className="main-navbar">
            <Toolbar>
                <NavLink to="/">
                    <img src="/static/img/head-logo-dark.png" className="brand-image" alt="Logo" />
                </NavLink>
                <Typography component="div" className="beta-marker">
                    BETA
                </Typography>

                <Box sx={{ marginLeft: "auto" }}>
                    {props.activeDocument && (
                        <Tooltip title="Download specification">
                            <Button color="inherit" className="nav-button" onClick={handleExportButtonClick}>
                                Export
                            </Button>
                        </Tooltip>
                    )}
                    {showCreateNewPasteButton && <NavLinkButton to="/" title="Create new paste" />}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
