export const objectToJson = (obj) => JSON.stringify(obj, null, 2);

export const isValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    // source: https://melvingeorge.me/blog/check-if-string-valid-uuid-regex-javascript
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
};

export const docUuidToUrl = (uuid, absoluteUrl) => `${absoluteUrl ? process.env.REACT_APP_SITE_URL : ""}/doc/${uuid}`;
