import React, { useState } from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Stack,
    Snackbar,
    Alert,
    Box,
    AlertTitle,
} from "@mui/material";
import { pasteExpiration, pasteExpirationValues } from "const";
import { documentsApi } from "api";
import UploadedDocInfoModal from "components/UploadedDocInfoModal";
import { contentToObject } from "helpers/converter";
import { docUuidToUrl } from "helpers/util";

export default function UploadWidget(props) {
    const [expiration, setExpiration] = useState(pasteExpiration.never);
    const [uploadedDocInfo, setUploadedDocInfo] = useState(null);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorSnackbarVisible, setErrorSnackbarVisible] = useState(false);

    const handleChange = (event) => {
        setExpiration(event.target.value);
    };

    const pasteExpirationOptions = pasteExpirationValues.map((item) => {
        return (
            <MenuItem value={item.value} key={item.value}>
                {item.label}
            </MenuItem>
        );
    });

    const onSubmit = () => {
        const jsonContent = contentToObject(props.codeContent);
        if (jsonContent === null) {
            setErrorMessage("File format unknown. Expecting JSON or YAML file.");
            setErrorSnackbarVisible(true);
            return false;
        }

        const data = {
            content: JSON.stringify(jsonContent),
            format: "json",
            title: jsonContent.info?.title || "",
            description: jsonContent.info?.description || "",
            expiration,
        };

        documentsApi
            .create(data)
            .then((response) => {
                const documentExists = response.status === 200;
                setUploadedDocInfo({
                    uuid: response.data.uuid,
                    pasteUrl: docUuidToUrl(response.data.uuid, true),
                    documentExists,
                });
                setInfoDialogOpen(true);
            })
            .catch((error) => {
                if (error.response?.data) {
                    setErrorMessage(error.response.data.details);
                } else {
                    setErrorMessage("Error uploading document.");
                }
                setErrorSnackbarVisible(true);
            });
    };

    return (
        <>
            <Stack spacing={2} direction="column" sx={{ marginBottom: "2rem" }}>
                <Alert severity="info" icon={false}>
                    <AlertTitle sx={{ marginBottom: "1rem" }}>
                        Welcome to swaggerbin, Swagger/OpenAPI document sharing service.
                    </AlertTitle>
                    <Box>
                        In order to create beautiful documentation and share it with your team mates, paste your JSON or
                        YAML file containing Swagger/OpenAPI specification in the text area. Select your paste
                        expiration and click <strong>Create new paste</strong> to create share link.
                    </Box>
                </Alert>
            </Stack>
            <Stack spacing={2} direction="column">
                <FormControl fullWidth>
                    <InputLabel id="upload-widget-expiration-select">Document expiration</InputLabel>
                    <Select
                        labelId="upload-widget-expiration-select"
                        id="demo-simple-select"
                        value={expiration}
                        label="Document expiration"
                        onChange={handleChange}
                    >
                        {pasteExpirationOptions}
                    </Select>
                </FormControl>
                <Button variant="contained" onClick={() => onSubmit()}>
                    Create New Paste
                </Button>
            </Stack>

            {uploadedDocInfo && (
                <UploadedDocInfoModal
                    docInfo={uploadedDocInfo}
                    isOpen={infoDialogOpen}
                    closeHandler={setInfoDialogOpen}
                />
            )}

            <Snackbar
                open={errorSnackbarVisible}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setErrorSnackbarVisible(false)}
            >
                <Alert severity="error" variant="filled">
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
