const KEYS = {
    DOCUMENT_HISTORY: "documentHistory",
};

class StoreBase {
    storage;

    serialize(data) {
        return JSON.stringify(data);
    }

    deserialize(str) {
        return JSON.parse(str);
    }

    setItem(key, value) {
        this.storage.setItem(key, value);
    }

    getItem(key) {
        return this.storage.getItem(key);
    }

    setObject(key, obj) {
        this.setItem(key, this.serialize(obj));
    }

    getObject(key) {
        return this.deserialize(this.getItem(key));
    }

    clear() {
        this.storage.clear();
    }

    removeItem(key) {
        this.storage.removeItem(key);
    }
}

export class SessionStore extends StoreBase {
    storage = sessionStorage;
}

export class LocalStore extends StoreBase {
    storage = localStorage;

    getDocumentHistory() {
        const history = this.getObject(KEYS.DOCUMENT_HISTORY);
        return history || [];
    }

    addDocumentToHistory(documentData, maxDocumentsInHistory) {
        let history = this.getDocumentHistory();

        // remove existing element
        history = history.filter((item) => item.uuid !== documentData.uuid);
        // insert document at 0 position
        history.splice(0, 0, documentData);
        // trim array
        history = history.slice(0, maxDocumentsInHistory);

        this.setObject(KEYS.DOCUMENT_HISTORY, history);
        return history;
    }
}

export const sessionStore = new SessionStore();
export const localStore = new LocalStore();
