import React from "react";

export default function CodeField(props) {
    return (
        <textarea
            className="code-field language-yaml"
            value={props.content}
            onChange={(event) => props.onCodeChange(event.target.value)}
        ></textarea>
    );
}
