import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Slide,
    FormControl,
    InputLabel,
    Input,
    InputAdornment,
    Tooltip,
    IconButton,
    Alert,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { NavLink } from "react-router-dom";
import { docUuidToUrl } from "helpers/util";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadedDocInfoModal(props) {
    const [urlCopied, setUrlCopied] = useState(false);

    const handleCopyUrlClick = () => {
        navigator.clipboard.writeText(props.docInfo.pasteUrl);
        setUrlCopied(true);
        setTimeout(() => {
            setUrlCopied(false);
        }, 2000);
    };

    const handleCopyUrlMouseDown = (event) => {
        event.preventDefault();
    };

    const copyTooltipTitle = urlCopied ? "Copied" : "Copy link";
    const dialogContentText = `Your document is uploaded and ready to share.
        The link to the document will not be publicly shared and
        it's up to your discretion to use it properly.`;

    return (
        <>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => props.closeHandler(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>You created a new document paste</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">{dialogContentText}</DialogContentText>
                    {props.docInfo.documentExists && (
                        <Alert severity="info" sx={{ marginTop: "1rem" }}>
                            Document already exists.
                        </Alert>
                    )}
                    <FormControl variant="standard" fullWidth sx={{ marginTop: "2rem" }}>
                        <InputLabel htmlFor="document-url">Document URL</InputLabel>
                        <Input
                            id="document-url"
                            type="text"
                            label="Document URL"
                            value={props.docInfo.pasteUrl}
                            readOnly={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title={copyTooltipTitle}>
                                        <IconButton
                                            aria-label="copy link to clipboard"
                                            onClick={handleCopyUrlClick}
                                            onMouseDown={handleCopyUrlMouseDown}
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => props.closeHandler(false)}
                        component={NavLink}
                        to={docUuidToUrl(props.docInfo.uuid)}
                        sx={{ marginRight: "auto" }}
                    >
                        Open document
                    </Button>
                    <Button onClick={() => props.closeHandler(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
