export const API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const pasteExpiration = {
    never: "never",
    oneHour: "one-hour",
    oneDay: "one-day",
    oneWeek: "one-week",
    twoWeeks: "two-weeks",
    oneMonth: "one-month",
    sixMonths: "six-months",
    oneYear: "one-year",
};

export const pasteExpirationValues = [
    {
        label: "Never",
        value: pasteExpiration.never,
    },
    {
        label: "One Hour",
        value: pasteExpiration.oneHour,
    },
    {
        label: "One Day",
        value: pasteExpiration.oneDay,
    },
    {
        label: "One Week",
        value: pasteExpiration.oneWeek,
    },
    {
        label: "Two Weeks",
        value: pasteExpiration.twoWeeks,
    },
    {
        label: "One Month",
        value: pasteExpiration.oneMonth,
    },
    {
        label: "Six Months",
        value: pasteExpiration.sixMonths,
    },
    {
        label: "One Year",
        value: pasteExpiration.oneYear,
    },
];

export const MAX_DOCUMENTS_IN_HISTORY = 5;
