import { load as yamlLoad } from "js-yaml";

export const jsonToObject = (content) => {
    try {
        return JSON.parse(content);
    } catch (err) {
        return null;
    }
};

export const yamlToObject = (content) => {
    try {
        return yamlLoad(content);
    } catch (err) {
        return null;
    }
};

export const getContentFormat = (content) => {
    if (jsonToObject(content) !== null) return "JSON";
    if (yamlToObject(content) !== null) return "YAML";
    return null;
};

export const contentToObject = (content) => {
    let result;

    result = jsonToObject(content);
    if (result !== null) {
        return result;
    }
    result = yamlToObject(content);
    if (result !== null && typeof result === "object") {
        return result;
    }
    return null;
};
