import React from "react";
import { Box, Typography } from "@mui/material";

export default function NotFound404(props) {
    return (
        <>
            <Box className="not-found-404">
                <Box
                    component="img"
                    alt="SwaggerBin"
                    className="swaggerbin-logo"
                    src="/static/img/swaggerbin-logo-symbol.svg"
                />
                <Typography component="div" variant="h1" className="title">
                    - 404 -
                </Typography>
                {props.errorMessage && <p className="error-message">{props.errorMessage}</p>}
            </Box>
        </>
    );
}
