import { axiosInstance, ApiBase } from "./Common";

class DocumentsApi extends ApiBase {
    get = async (documentUuid) => {
        return axiosInstance.get(`/documents/${documentUuid}`, {});
    };
    create = async (data) => {
        const formData = new FormData();
        const blob = new Blob([data.content], { type: "multipart/form-data" });
        formData.append("sourceFile", blob);
        formData.append("format", data.format);
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("expiration", data.expiration);

        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return axiosInstance.post(`/documents`, formData, options);
    };
}

export const documentsApi = new DocumentsApi();
